'use client'

import classnames from 'classnames'

import { Badge, Button, ButtonWhatsapp, Divider, Icon, If } from '..'
import Radio from '../Form/Vanilla/Radio'

import { Action, Details } from './components'

import { usePlan } from './usePlan'

import styles from './Plan.module.scss'

const defaultAction = {
  id: 'plan-seeDetails-button',
  text: 'Ver detalhes',
  leftIcon: 'equifax-finance-chip',
  rightIcon: 'equifax-arrow-down'
}

export const Plan = ({
  children,
  icon,
  plan = {},
  customClass,
  selectedPlan,
  expandedPlans = {},
  action = defaultAction,
  isActive,
  setExpandedPlans,
  openPaymentDetails,
  onClickCTA,
  onClickCard
}) => {
  const {
    loadingButtonID,
    isNotCustom,
    onClickAction,
    onSelectPlan,
    setLoadingButtonID
  } = usePlan({
    plan,
    action,
    setExpandedPlans,
    onClickCard
  })

  return (
    <>
      <div
        id={`plan-${plan.id}-card`}
        onClick={onSelectPlan}
        className={classnames(styles.plan, {
          ['--gtm-click']: isNotCustom && onClickCard,
          [styles['plan--custom']]: !isNotCustom,
          [styles['plan--withCta']]: onClickCTA,
          [styles['plan--clickable']]: isNotCustom && onClickCard,
          [styles['plan--active']]: isActive || selectedPlan === plan.id,
          [customClass]: customClass
        })}
      >
        <div className={styles.plan__heading}>
          <div>
            <If condition={icon}>
              <Icon
                name={icon}
                size="small"
                color="white"
                className={styles.plan__icon}
              />
            </If>
            <label htmlFor="plan">{plan.name}</label>
          </div>

          <If condition={plan.is_best_selling}>
            <Badge
              round
              color="red"
              icon="equifax-stars"
              text="Mais vendido"
              customClass={styles.plan__badge}
            />
          </If>

          <If condition={onClickCard && isNotCustom}>
            <Radio
              name="plan"
              value={plan.id}
              checked={selectedPlan === plan.id}
              onChange={(_, value) => onClickCard(value)}
            />
          </If>
        </div>

        <If condition={plan.discount}>
          <p className={styles.plan__discount}>
            Economize {plan.discount} por consulta
          </p>
        </If>

        <div
          className={`${styles.plan__content} ${styles[`plan__content--${plan.id}`]} ${!plan.discount && styles['plan__content--emptyDiscount']}`}
        >
          <div>
            <p className={styles.plan__highlightText}>{plan.highlight_text}</p>
            {isNotCustom && (
              <p className={styles.plan__monthlyPrice}>assinatura mensal</p>
            )}
            <If condition={isNotCustom}>
              <p className={styles.plan__text}>
                Média de <b>{plan.consultations_per_month} consultas</b> por mês
              </p>

              <Divider />
            </If>
            <p className={styles.plan__text}>{plan.text}</p>
            <If
              condition={isNotCustom}
              renderIf={
                <p className={styles.plan__text}>
                  <b>Pagamento via boleto</b>
                </p>
              }
              renderElse={
                <>
                  <p className={styles.plan__text}>Para você que:</p>
                  <ul className={styles.plan__list}>
                    <li>Quer mais consultas</li>
                    <li>Quer entender melhor</li>
                    <li>Quer um plano sob medida</li>
                  </ul>
                </>
              }
            />
            <If condition={isNotCustom}>
              <Action
                {...action}
                id={`${action.id}--${plan.id}`}
                isExpanded={expandedPlans[plan.id]}
                onClick={onClickAction}
              />
            </If>

            <Details
              id={plan.id}
              details={plan.details}
              isExpanded={expandedPlans[plan.id]}
              openPaymentDetails={openPaymentDetails}
            />
          </div>

          {children}

          <If condition={plan.id === 'custom'}>
            <ButtonWhatsapp variant="link" id="plans-whatsapp-button">
              Falar com especialista
            </ButtonWhatsapp>
          </If>

          <If condition={onClickCTA && plan.id !== 'custom'}>
            <Button
              variant="primary"
              id={`${plan.id}Plan-wantToHire-button`}
              isLoading={loadingButtonID === plan.id}
              onClick={() => {
                onClickCTA(plan.id)
                setLoadingButtonID(plan.id)
              }}
            >
              Quero contratar
            </Button>
          </If>
        </div>
      </div>
    </>
  )
}
